<template>
  <v-dialog v-model="dialog" persistent width="880">
    <v-card>
      <v-card-title>Add stock to</v-card-title>
      <v-card-text>
        <v-text-field
          label="search"
          prepend-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>
        <v-data-table
          :items="filtered_items"
          :headers="headers"
          :search="search"
        >
          <template v-slot:item.action="{ item }">
            <v-btn @click="merge(item)" small color="primary">Add Stock</v-btn>
          </template>
          <template v-slot:item.is_deleted="{ item }">
            <span v-if="item.is_deleted" class="red--text">Deleted</span>
            <span v-else class="green--text">Active</span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="$emit('close')"> Close</v-btn>
        <v-spacer></v-spacer>
        <p class="caption grey--text">mergeItemModal.vue</p>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex/dist/vuex.common.js";
export default {
  props: ["item"],
  data() {
    return {
      search: "",
      dialog: true,
      loading: false,
      headers: [
        { text: "", value: "action" },
        { text: "ID", value: "id" },
        { text: "Item Name", value: "item_name" },
        { text: "Batch", value: "import_batch.batch_description" },
        { text: "Selling Price", value: "selling_price" },
        { text: "Quantity", value: "quantity" },
        { text: "Status", value: "is_deleted" },
        { text: "Branch", value: "branch.branch_name" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      all_items: "item/all_items",
      user: "auth/user",
    }),
    filtered_items() {
      var filtered1 = this.all_items.filter((i) => {
        return i.is_deleted == 0 && i.branch_id == this.item.to_branch_id;
      });
      return filtered1;
    },
  },
  methods: {
    ...mapActions({
      add_item_transfer: "item_transfer/add_item_transfer",
      edit_item: "item/edit",
    }),
    submit() {
      return;
    },
    merge(item) {
      if (window.confirm("Are you sure you want to proceed?")) {
        const modifyStock = (itemId, quantity, action) =>
          axios.put(`/modify_stock/${itemId}/${quantity}/${action}`);

        const handleError = (err) => alert(err);
        // Add to addressed branch
        modifyStock(item.id, this.item.quantity, "add").catch(handleError);

        // Deduct from the original branch
        modifyStock(this.item.item_id, this.item.quantity, "subtract")
          .then(
            (res) => {
              // update current items table
              this.edit_item(res.data);
              this.add_item_transfer(this.item)
                .then(() => {
                  this.$emit("close");
                  alert("Successfully processed a new item transfer");
                })
                .catch(handleError);
            }
            // add item transfer record
          )
          .catch(handleError);
      }
    },
  },
  created() {
    this.search = this.item.item_name;
  },
};
</script>

<style lang="scss" scoped></style>
